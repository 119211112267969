import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/scss';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../components/css/Swiper.scss";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, {
  Pagination
} from "swiper/core";
import background5Genabled from "../images/technology/5G-enabledby.mp4";
import backgroundAIenabled from "../images/technology/AI-enabledby.mp4";
import { window } from 'browser-monads';
import { Navigation, Keyboard } from "swiper";

SwiperCore.use([Pagination, Navigation]);



export default function SwiperSlid() {
const slideObj=[
    {
        Maintitle:`5G enabled 
        by AI`,
        videoURL:background5Genabled,
        MarketText : `RF spectrum monitoring 
        Indoor location positioning
        5G massive MIMO schedulers
        Beam pattern optimization and more...`
    },
    {
      Maintitle:`AI enabled 
        by 5G`,
        videoURL:backgroundAIenabled,
        MarketText : `Image Classification / Detection 
        Computer Vision Anomaly Detection
        Autonomous Navigation
        Asset Tracking and more...`
    },
]
    return (
    <div className="container">
      <Swiper
        centeredSlides={true}
        loop={true}
        spaceBetween={0}
        speed={800} 
        navigation={true}  
        modules={[Navigation, Keyboard]}
        slideToClickedSlide={true}
        // slidesPerView={window.innerWidth < 2500 ? 2 : "auto"}
        pagination={{
          clickable: true
        }}
        className="mySwiper"
        // breakpoints={{
        //   // when window width is >= 640px
        //   360: {
        //     width: 360,
        //     slidesPerView: 1,
        //   },
        // }}
        slidesPerView={window.innerWidth < 575 ? 1 : 2}
      >
        
           {slideObj.map((slide,index)=>{
               return(
                <SwiperSlide key={`swiper-${index}`}>
                   <div className="slides__" >
                       <Row>
                           <Col md="7">
                           <div className="slideinner_img">
                                {/* <img src={slide.imageURL} alt={`slide-${index}`} /> */}
                                <video autoPlay muted loop  playsInline  alt={`slide-${index}`}  data-autoplay>
                                    <source src={slide.videoURL}/>
                                </video>
                            </div>
                           </Col>
                           <Col md="5">
                            <div className="slideinner_text market_slide_text">
                                    <h2>{slide.Maintitle}</h2>
                                    <p>{slide.MarketText}</p>
                                </div>
                           </Col>
                       </Row>


                      
                   </div>
                </SwiperSlide>
               )
           })}
        
      </Swiper>
    </div>
  );
}
